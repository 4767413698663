/*****************************************************************************************************************************/
/****** WARNING! THIS FILE IS AUTO-GENERATED. DO NOT EDIT IT MANUALLY OR OTHERWISE YOUR CHANGES WILL BE OVERWRITTEN!! ******/
/*****************************************************************************************************************************/

const Colors = {
  "color": {
    "primary": "dsl-primary-color",
    "secondary": "dsl-secondary-color",
    "pane": "dsl-pane-color",
    "content": "dsl-content-color",
    "defaultText": "dsl-defaultText-color",
    "secondaryText": "dsl-secondaryText-color",
    "action": "dsl-action-color",
    "success": "dsl-success-color",
    "error": "dsl-error-color",
    "warning": "dsl-warning-color",
    "info": "dsl-info-color",
    "white": "dsl-white-color",
    "border": "dsl-border-color"
  },
  "bg": {
    "primary": "dsl-primary-bg",
    "secondary": "dsl-secondary-bg",
    "pane": "dsl-pane-bg",
    "content": "dsl-content-bg",
    "defaultText": "dsl-defaultText-bg",
    "secondaryText": "dsl-secondaryText-bg",
    "action": "dsl-action-bg",
    "success": "dsl-success-bg",
    "error": "dsl-error-bg",
    "warning": "dsl-warning-bg",
    "info": "dsl-info-bg",
    "white": "dsl-white-bg",
    "border": "dsl-border-bg"
  },
  "fill": {
    "primary": "dsl-primary-fill",
    "secondary": "dsl-secondary-fill",
    "pane": "dsl-pane-fill",
    "content": "dsl-content-fill",
    "defaultText": "dsl-defaultText-fill",
    "secondaryText": "dsl-secondaryText-fill",
    "action": "dsl-action-fill",
    "success": "dsl-success-fill",
    "error": "dsl-error-fill",
    "warning": "dsl-warning-fill",
    "info": "dsl-info-fill",
    "white": "dsl-white-fill",
    "border": "dsl-border-fill"
  },
  "stroke": {
    "primary": "dsl-primary-stroke",
    "secondary": "dsl-secondary-stroke",
    "pane": "dsl-pane-stroke",
    "content": "dsl-content-stroke",
    "defaultText": "dsl-defaultText-stroke",
    "secondaryText": "dsl-secondaryText-stroke",
    "action": "dsl-action-stroke",
    "success": "dsl-success-stroke",
    "error": "dsl-error-stroke",
    "warning": "dsl-warning-stroke",
    "info": "dsl-info-stroke",
    "white": "dsl-white-stroke",
    "border": "dsl-border-stroke"
  },
  "borderColor": {
    "primary": "dsl-primary-borderColor",
    "secondary": "dsl-secondary-borderColor",
    "pane": "dsl-pane-borderColor",
    "content": "dsl-content-borderColor",
    "defaultText": "dsl-defaultText-borderColor",
    "secondaryText": "dsl-secondaryText-borderColor",
    "action": "dsl-action-borderColor",
    "success": "dsl-success-borderColor",
    "error": "dsl-error-borderColor",
    "warning": "dsl-warning-borderColor",
    "info": "dsl-info-borderColor",
    "white": "dsl-white-borderColor",
    "border": "dsl-border-borderColor"
  },
  "placeholder": {
    "primary": "dsl-primary-placeholder",
    "secondary": "dsl-secondary-placeholder",
    "pane": "dsl-pane-placeholder",
    "content": "dsl-content-placeholder",
    "defaultText": "dsl-defaultText-placeholder",
    "secondaryText": "dsl-secondaryText-placeholder",
    "action": "dsl-action-placeholder",
    "success": "dsl-success-placeholder",
    "error": "dsl-error-placeholder",
    "warning": "dsl-warning-placeholder",
    "info": "dsl-info-placeholder",
    "white": "dsl-white-placeholder",
    "border": "dsl-border-placeholder"
  },
  "palette": {
    "color": {
      "primary_content": "dsl-palette-primary_content-color",
      "secondary_content": "dsl-palette-secondary_content-color",
      "pane": "dsl-palette-pane-color",
      "default_text": "dsl-palette-default_text-color",
      "secondary_text": "dsl-palette-secondary_text-color",
      "action": "dsl-palette-action-color",
      "green": "dsl-palette-green-color",
      "red": "dsl-palette-red-color",
      "blue": "dsl-palette-blue-color",
      "yellow": "dsl-palette-yellow-color",
      "white": "dsl-palette-white-color",
      "border": "dsl-palette-border-color"
    },
    "bg": {
      "primary_content": "dsl-palette-primary_content-bg",
      "secondary_content": "dsl-palette-secondary_content-bg",
      "pane": "dsl-palette-pane-bg",
      "default_text": "dsl-palette-default_text-bg",
      "secondary_text": "dsl-palette-secondary_text-bg",
      "action": "dsl-palette-action-bg",
      "green": "dsl-palette-green-bg",
      "red": "dsl-palette-red-bg",
      "blue": "dsl-palette-blue-bg",
      "yellow": "dsl-palette-yellow-bg",
      "white": "dsl-palette-white-bg",
      "border": "dsl-palette-border-bg"
    },
    "fill": {
      "primary_content": "dsl-palette-primary_content-fill",
      "secondary_content": "dsl-palette-secondary_content-fill",
      "pane": "dsl-palette-pane-fill",
      "default_text": "dsl-palette-default_text-fill",
      "secondary_text": "dsl-palette-secondary_text-fill",
      "action": "dsl-palette-action-fill",
      "green": "dsl-palette-green-fill",
      "red": "dsl-palette-red-fill",
      "blue": "dsl-palette-blue-fill",
      "yellow": "dsl-palette-yellow-fill",
      "white": "dsl-palette-white-fill",
      "border": "dsl-palette-border-fill"
    },
    "stroke": {
      "primary_content": "dsl-palette-primary_content-stroke",
      "secondary_content": "dsl-palette-secondary_content-stroke",
      "pane": "dsl-palette-pane-stroke",
      "default_text": "dsl-palette-default_text-stroke",
      "secondary_text": "dsl-palette-secondary_text-stroke",
      "action": "dsl-palette-action-stroke",
      "green": "dsl-palette-green-stroke",
      "red": "dsl-palette-red-stroke",
      "blue": "dsl-palette-blue-stroke",
      "yellow": "dsl-palette-yellow-stroke",
      "white": "dsl-palette-white-stroke",
      "border": "dsl-palette-border-stroke"
    },
    "borderColor": {
      "primary_content": "dsl-palette-primary_content-borderColor",
      "secondary_content": "dsl-palette-secondary_content-borderColor",
      "pane": "dsl-palette-pane-borderColor",
      "default_text": "dsl-palette-default_text-borderColor",
      "secondary_text": "dsl-palette-secondary_text-borderColor",
      "action": "dsl-palette-action-borderColor",
      "green": "dsl-palette-green-borderColor",
      "red": "dsl-palette-red-borderColor",
      "blue": "dsl-palette-blue-borderColor",
      "yellow": "dsl-palette-yellow-borderColor",
      "white": "dsl-palette-white-borderColor",
      "border": "dsl-palette-border-borderColor"
    },
    "placeholder": {
      "primary_content": "dsl-palette-primary_content-placeholder",
      "secondary_content": "dsl-palette-secondary_content-placeholder",
      "pane": "dsl-palette-pane-placeholder",
      "default_text": "dsl-palette-default_text-placeholder",
      "secondary_text": "dsl-palette-secondary_text-placeholder",
      "action": "dsl-palette-action-placeholder",
      "green": "dsl-palette-green-placeholder",
      "red": "dsl-palette-red-placeholder",
      "blue": "dsl-palette-blue-placeholder",
      "yellow": "dsl-palette-yellow-placeholder",
      "white": "dsl-palette-white-placeholder",
      "border": "dsl-palette-border-placeholder"
    },
    "hex": {
      "primary_content": "#f5f8fc",
      "secondary_content": "#e5f1ff",
      "pane": "#FFFFFF",
      "default_text": "#000000",
      "secondary_text": "#5D5D5D",
      "action": "#007BFF",
      "green": "#159B64",
      "red": "#E12121",
      "blue": "#87CEFA",
      "yellow": "#FFD640",
      "white": "#FFFFFF",
      "border": "#E9E9E9"
    }
  },
  "theme": {
    "light": "dsl-theme-light"
  },
  "hover": {
    "color": {
      "primary": "dsl-primary-color--hover",
      "secondary": "dsl-secondary-color--hover",
      "pane": "dsl-pane-color--hover",
      "content": "dsl-content-color--hover",
      "defaultText": "dsl-defaultText-color--hover",
      "secondaryText": "dsl-secondaryText-color--hover",
      "action": "dsl-action-color--hover",
      "success": "dsl-success-color--hover",
      "error": "dsl-error-color--hover",
      "warning": "dsl-warning-color--hover",
      "info": "dsl-info-color--hover",
      "white": "dsl-white-color--hover",
      "border": "dsl-border-color--hover"
    },
    "bg": {
      "primary": "dsl-primary-bg--hover",
      "secondary": "dsl-secondary-bg--hover",
      "pane": "dsl-pane-bg--hover",
      "content": "dsl-content-bg--hover",
      "defaultText": "dsl-defaultText-bg--hover",
      "secondaryText": "dsl-secondaryText-bg--hover",
      "action": "dsl-action-bg--hover",
      "success": "dsl-success-bg--hover",
      "error": "dsl-error-bg--hover",
      "warning": "dsl-warning-bg--hover",
      "info": "dsl-info-bg--hover",
      "white": "dsl-white-bg--hover",
      "border": "dsl-border-bg--hover"
    },
    "fill": {
      "primary": "dsl-primary-fill--hover",
      "secondary": "dsl-secondary-fill--hover",
      "pane": "dsl-pane-fill--hover",
      "content": "dsl-content-fill--hover",
      "defaultText": "dsl-defaultText-fill--hover",
      "secondaryText": "dsl-secondaryText-fill--hover",
      "action": "dsl-action-fill--hover",
      "success": "dsl-success-fill--hover",
      "error": "dsl-error-fill--hover",
      "warning": "dsl-warning-fill--hover",
      "info": "dsl-info-fill--hover",
      "white": "dsl-white-fill--hover",
      "border": "dsl-border-fill--hover"
    },
    "stroke": {
      "primary": "dsl-primary-stroke--hover",
      "secondary": "dsl-secondary-stroke--hover",
      "pane": "dsl-pane-stroke--hover",
      "content": "dsl-content-stroke--hover",
      "defaultText": "dsl-defaultText-stroke--hover",
      "secondaryText": "dsl-secondaryText-stroke--hover",
      "action": "dsl-action-stroke--hover",
      "success": "dsl-success-stroke--hover",
      "error": "dsl-error-stroke--hover",
      "warning": "dsl-warning-stroke--hover",
      "info": "dsl-info-stroke--hover",
      "white": "dsl-white-stroke--hover",
      "border": "dsl-border-stroke--hover"
    },
    "borderColor": {
      "primary": "dsl-primary-borderColor--hover",
      "secondary": "dsl-secondary-borderColor--hover",
      "pane": "dsl-pane-borderColor--hover",
      "content": "dsl-content-borderColor--hover",
      "defaultText": "dsl-defaultText-borderColor--hover",
      "secondaryText": "dsl-secondaryText-borderColor--hover",
      "action": "dsl-action-borderColor--hover",
      "success": "dsl-success-borderColor--hover",
      "error": "dsl-error-borderColor--hover",
      "warning": "dsl-warning-borderColor--hover",
      "info": "dsl-info-borderColor--hover",
      "white": "dsl-white-borderColor--hover",
      "border": "dsl-border-borderColor--hover"
    },
    "placeholder": {
      "primary": "dsl-primary-placeholder--hover",
      "secondary": "dsl-secondary-placeholder--hover",
      "pane": "dsl-pane-placeholder--hover",
      "content": "dsl-content-placeholder--hover",
      "defaultText": "dsl-defaultText-placeholder--hover",
      "secondaryText": "dsl-secondaryText-placeholder--hover",
      "action": "dsl-action-placeholder--hover",
      "success": "dsl-success-placeholder--hover",
      "error": "dsl-error-placeholder--hover",
      "warning": "dsl-warning-placeholder--hover",
      "info": "dsl-info-placeholder--hover",
      "white": "dsl-white-placeholder--hover",
      "border": "dsl-border-placeholder--hover"
    }
  }
};
export default Colors;

