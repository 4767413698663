import React from "react";
import styles from "./Typography.module.scss";
import { TypographyConfig } from "../config";
import classNames from "classnames";

export enum TType {
  HeaderBold = "HeaderBold",
  HeaderRegular = "HeaderRegular",
  HeaderLight = "HeaderLight",
  BodyBold = "BodyBold",
  BodyRegular = "BodyRegular",
  BodyLight = "BodyLight",
}

export interface TypographyProps {
  type: TType;
  children?: any;
  className?: string | string[];
  size?: number;
  style?: any;
}

const Typography = (props: TypographyProps) => {
  let { className, type, ...other } = props;
  let Component = TypographyConfig.TTypes[props.type].tag;
  let style: any = props.style || {};
  if (props.size) {
    style.fontSize = props.size;
  }
  return (
    // @ts-ignore
    <Component
      className={classNames(styles[type], styles["typography"], className)}
      style={style}
      {...other}
    >
      {props.children}
    </Component>
  );
};
export default Typography;
