import React, { useEffect, useState } from "react";
import Typography, { TType } from "../../DSL/Typography/Typography";
import observeMobx from "../../observeMobx";
import start from "../../start.json";
import { SketchPicker } from "react-color";
import Button from "../../DSL/Button/Button";

const DSLPage = (props: any) => {
  const [grid, setGrid] = useState<string[][]>([]);
  const [activeRow, setActiveRow] = useState<number | undefined>();
  const [activeCell, setActiveCell] = useState<number | undefined>();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    getPixels();
  }, []);

  const getPixels = () => {
    fetch("https://mya-api.libtechnologies.io/get-pixels")
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setStatus(res.status);
        setGrid(res?.result?.value);
      });
  };

  const handleColorChange = (hex: string) => {
    const updated = JSON.parse(JSON.stringify(grid));
    // @ts-ignore
    updated[activeRow][activeCell] = hex;
    setGrid(updated);
    fetch("https://mya-api.libtechnologies.io/update-pixels", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        value: grid,
      }),
    });
  };

  const closeColorPicker = () => {
    setActiveRow(undefined);
    setActiveCell(undefined);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography style={{ marginTop: 20 }} size={20} type={TType.HeaderBold}>
        Here be dragons! (and donuts)
      </Typography>
      <div style={{ position: "absolute", top: 20, right: 20 }}>
        {activeRow !== undefined && activeCell !== undefined && (
          <Button size="md" onClick={closeColorPicker}>
            Close color picker
          </Button>
        )}
      </div>
      {loading ? (
        <Typography style={{ marginTop: 30 }} type={TType.BodyRegular}>
          Loading some stuff from cheap infrastructure ... bare with 🙏
        </Typography>
      ) : !status ? (
        <Typography style={{ marginTop: 30 }} type={TType.BodyRegular}>
          Something horrible went wrong, please contact the moron that made this
        </Typography>
      ) : (
        <div
          style={{
            width: 1100,
            height: 600,
            border: "1px solid #d4d4d4",
            marginTop: 20,
          }}
        >
          {grid.map((row, rowIndex) => (
            <div key={rowIndex} style={{ display: "flex" }}>
              {row.map((cell, cellIndex) => (
                <>
                  <div
                    key={cellIndex}
                    style={{
                      width: 10,
                      height: 10,
                      border: "0.5px solid #d4d4d4",
                      backgroundColor: cell,
                    }}
                    onClick={() => {
                      setActiveRow(rowIndex);
                      setActiveCell(cellIndex);
                    }}
                  >
                    {activeRow === rowIndex && activeCell === cellIndex && (
                      <div style={{ position: "absolute", marginTop: 20 }}>
                        <SketchPicker
                          color={cell}
                          onChange={(color) => handleColorChange(color.hex)}
                        />
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DSLPage;
